





































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {IApiJob} from '@/shared/models/IApiJob';
import {Prop} from 'vue-property-decorator';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Icons} from '@/shared/components/icon/icons';
import {DATE_SERVICE} from '@/shared/services/date.service';
import {PublicJobsRouter} from '@/modules/jobs/router';
import RsButton from '@/shared/components/buttons/button.vue';

@Component({
  name: 'rs-job-card-public',
  components: {RsButton, RsIcon}
})
export default class JobCardPublic extends Vue {
  @Prop() job: IApiJob;

  Icons = Icons;
  JobsRouter = PublicJobsRouter

  get isNew(): boolean{
    return DATE_SERVICE.diff(this.job.createdAt, 'day') > -14
  }

  formatSalary(salary: number){
    if(salary % 1000 > 0){
      return `${Math.floor(salary/1000)}.${Math.floor(salary%1000/100)}`
    }
    return Math.floor(salary/1000);
  }
}
