


































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsJobCard from '@/modules/dashboard/modules/jobs/components/job-card.vue';
import {Action, Getter, Mutation} from 'vuex-class';
import {PublicJobsActions} from '@/modules/jobs/store/actions';
import JobsModule from '@/modules/jobs';
import {PublicJobsGetters} from '@/modules/jobs/store/getters';
import {IApiJob} from '@/shared/models/IApiJob';
import RsLoading from '@/shared/components/loading/loading.vue';
import RsJobCardPublic from '@/modules/jobs/components/job-card-public.vue';
import RsModal from '@/shared/components/modal/modal.vue';
import RsModalSignup from '@/modules/jobs/components/signup-modal.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import {API_SERVICE} from '@/shared/api/api.service';
import {CodetableResult, Codetables} from '@/shared/api/modules/codetable.api.service';
import {PublicJobsMutations} from '@/modules/jobs/store/mutations';

@Component({
  components: {
    RsCheckboxCard,
    RsButton, RsModalSignup, RsModal, RsJobCardPublic, RsLoading, RsJobCard}
})
export default class JobsIndex extends Vue {
  @Action(PublicJobsActions.GET_JOBS, {namespace: JobsModule.namespace}) getJobs: () => Promise<void>;
  @Mutation(PublicJobsMutations.SET_FILTERS, {namespace: JobsModule.namespace}) setFiltersMutation: (filters: {roles: string[]}) => void;
  @Action(PublicJobsActions.SET_FILTERS, {namespace: JobsModule.namespace}) setFilters: (filters: {roles: string[]}) => Promise<void>;
  @Action(PublicJobsActions.LOAD_MORE, {namespace: JobsModule.namespace}) loadMore: () => Promise<void>;
  @Getter(PublicJobsGetters.GET_JOBS, {namespace: JobsModule.namespace}) jobs: IApiJob[];
  @Getter(PublicJobsGetters.GET_TOTAL, {namespace: JobsModule.namespace}) total: number;

  loading = false;
  isSignUpModalOpen = false;

  filters = {
    roles: []
  }

  codetables: CodetableResult = {}

  loadingMore = false;

  load() {
    this.loadingMore = true;
    this.loadMore()
      .finally(() => {
        this.loadingMore = false;
      })
  }

  apply(id: string){
    localStorage.setItem(LOCALSTORAGE.JOB_APPLY, id)
    this.$track.sendEvent('JobApplySignup', {id: id})
    this.isSignUpModalOpen = true;
  }

  mounted() {

    this.$track.sendEvent('Jobs', {isPublic: true})
    if (this.jobs.length <= 0) {
      this.loading = true;
    }
    this.setFiltersMutation(this.filters)
    this.getJobs()
      .finally(() => {
        this.loading = false;
      })
    API_SERVICE.codetable.getCodetables(
      Codetables.DEVELOPER_ROLES,
    ).then((codetables) => {
      this.codetables = codetables
    })
  }

  onFilterChange(){
    this.setFilters(this.filters);
  }
}
